<!--ALL               "전체",         "0",    
    TEMP              "발주서 작성중",  "T"
    COMPLETE          "발행완료",      "C"
    AGREE             "발주서 서명완료", "G  DB저장은 하지 않음. - Display용
    CANCEL            "발주취소",      "E"
    중도해지            "중도해지",      "M"
    DELETE            "삭제",         "D"
-->
<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">발주서</h2>
      </div>
      <div class="body_section">
        <div class="pdf-viewer">
          <template v-if="pdfAllPages > 0">
            <Pdf
              v-for="i in pdfAllPages"
              :key="i"
              :page="i"
              :src="objectUrl"
              @page-loaded="onVuePdfPageLoaded"
              @error="onVuePdfError"
            />
          </template>
          <template v-if="isPdfError">
            <div class="pdfviewer-error">
              PDF 정보를 읽을수 없습니다.<br />
              <button class="pdfviewer-error__btn" @click="onClickPdfGenerator">
                PDF 재생성 <span class="icon-realod material-icons"> sync </span>
              </button>
            </div>
          </template>
        </div>

        <TableViewWithTitle>
          <template v-slot:body>
            <tr>
              <th>발주서</th>
              <td colspan="3">
                <template v-if="attachFile.electronicDocumentFile.length > 0">
                  <FileView :dataList="[attachFile.electronicDocumentFile[0]]" />
                </template>
              </td>
            </tr>

            <tr>
              <th>계약서 첨부파일</th>
              <td>
                <template v-if="attachFile.contractFile.length > 0">
                  <FileView :dataList="attachFile.contractFile" />
                </template>
              </td>
              <th>발주서 첨부파일</th>
              <td>
                <template v-if="attachFile.purchaseOrderFile.length > 0">
                  <FileView :dataList="attachFile.purchaseOrderFile" />
                </template>
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
        <template v-if="itemData.changeReason">
          <TableViewWithTitle title="변경 사유">
            <template v-slot:body>
              <tr>
                <th>변경사유</th>
                <td colspan="3" class="list_total td_preline">
                  {{ itemData.changeReason }}
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>
        <!-- 납기일 변경 히스토리 -->
        <!-- <GoodsDeadlineHistory
          v-if="itemData.goodsDeadlineHistory && itemData.goodsDeadlineHistory.length > 0"
          :orderCid="orderCid"
          :dataList="itemData.goodsDeadlineHistory"
        /> -->
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <template v-if="itemData.status !== 'M'">
          <!-- <button
            v-if="itemData.orderAgree === 'Y'"
            class="btn_large btn_primary"
            @click="onClickOrderDeadlineChange"
          >
            납기일 변경
          </button> -->
          <template>
            <button
              v-if="itemData.status === 'C' && itemData.orderAgree === 'N' && !itemData.contractId"
              class="btn_large btn_primary"
              @click="onClickOrderAgree"
            >
              동의
            </button>
          </template>
        </template>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isOrderAgreeConfirm"
        alertText="발주서에 동의하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertComfirm"
        @onClickPrimary="onClickAgree"
      />
      <OrderDeadlineChange
        v-if="isOrderDeadlineChange"
        :toGoodsDeadline.sync="toGoodsDeadline"
        :requestReason.sync="requestReason"
        @onClickOrderDeadlineChangeClose="onClickOrderDeadlineChangeClose"
        @onClickOrderDeadlineChangeSubmit="onClickOrderDeadlineChangeSubmit"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import Pdf from "vue-pdf";

import PageWithLayout from "@/components/layout/PageWithLayout";
import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView.vue";
import AttachedFileView from "@/components/common/file/AttachedFileView";

import GoodsDeadlineHistory from "@/components/user/order/view/GoodsDeadlineHistory.vue";
import OrderDeadlineChange from "@/components/user/order/popup/OrderDeadlineChange.vue";

import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";

import { getDateString } from "@/utils/dateUtils";
import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";
import { ObjectURLPdf } from "@/utils/pdfUtils";

export default {
  name: "OrderView",
  components: {
    Pdf,
    PageWithLayout,
    TableViewWithTitle,

    Sticky,
    FileView,
    AttachedFileView,
    AlertPopup,

    GoodsDeadlineHistory,
    OrderDeadlineChange,
  },
  mixins: [PageMixin],
  data() {
    return {
      orderCid: "",
      status: "",
      isOrderAgreeConfirm: false,
      objectUrl: "",
      pdfAllPages: 1,
      isPdfError: false,
      isPdfLoaded: false,
      itemData: {},
      attachFile: {
        electronicDocumentFile: [],
        contractFile: [], //계약서 첨부 파일
        purchaseOrderFile: [], //발주서 첨부 파일
        advancePaymentSuretyAttachFile: [], // 선급금이행증권 첨부파일
        contractSuretyAttachFile: [], // 계약이행증권 첨부파일
        defectSuretyAttachFile: [], // 하자이행증권 첨부파일
      },

      isOrderDeadlineChange: false,
      toGoodsDeadline: "",
      requestReason: "",
    };
  },
  computed: {},
  created() {
    this.orderCid = this.$route.params.id;

    this.getData(this.orderCid);
  },
  methods: {
    async getData(orderCid) {
      const path = `${this.$apiPath.ORDER}/${orderCid}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.itemData = _.cloneDeep(data);
      this.status = data.status;
      this.attachFile = {
        ...this.attachFile,
        ...this.itemData.attachFile,
      };

      if (this.attachFile.electronicDocumentFile.length < 1) {
        this.alert("PDF 정보를 읽을수 없습니다.");
        //return;
        this.isPdfError = true;
      } else {
        const viewPDFName = this.attachFile.electronicDocumentFile[0]?.orgFile;
        const viewPDFPath = this.attachFile.electronicDocumentFile[0]?.sysFilePath;
        await this.getPDFFile({ viewPDFName, viewPDFPath });
      }
    },
    async getPDFFile(obj) {
      const { viewPDFName, viewPDFPath } = obj;
      const arrayBuffer = await ApiService.shared.getFile(
        `${this.$apiPath.ATTACH_FILE}/${viewPDFPath}`,
      );
      this.objectUrl = ObjectURLPdf(arrayBuffer);
      const pdfData = Pdf.createLoadingTask(this.objectUrl);
      pdfData.promise.then((pdfInfo) => {
        this.pdfAllPages = pdfInfo.numPages;
      });
    },
    onVuePdfPageLoaded(number) {
      if (this.pdfAllPages === number) {
        this.isPdfLoaded = true;
      }
    },
    async onVuePdfError(error) {
      console.log("error :>> ", error);
      this.isPdfError = true;
      return;
    },
    onClickGoToList() {
      this.goToList();
    },
    goToList() {
      this.$router.push({
        path: this.$routerPath.ORDER_LIST,
        query: this.$route.query,
      });
    },

    onClickOrderAgree() {
      this.isOrderAgreeConfirm = true;
    },
    onClickCloseAlertComfirm() {
      this.isOrderAgreeConfirm = false;
    },
    async onClickAgree() {
      const path = `${this.$apiPath.ORDER}/${this.orderCid}${this.$apiPath.AGREE}`;

      const result = await ApiService.shared.putData(path, {});

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.isAgree = true;
      this.isOrderAgreeConfirm = false;
      window.location.reload();
    },
    onClickOrderDeadlineChange() {
      this.isOrderDeadlineChange = true;
    },
    onClickOrderDeadlineChangeClose() {
      this.isOrderDeadlineChange = false;
    },
    async onClickOrderDeadlineChangeSubmit() {
      const path = `${this.$apiPath.ORDER}/deadline`;
      const obj = {
        orderCid: this.orderCid,
        toGoodsDeadline: this.toGoodsDeadline,
        requestReason: this.requestReason,
      };
      const result = await ApiService.shared.postData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(result.text);
        return;
      }
      if (data) {
        this.alert("납기일 변경을 요청하였습니다.");
        this.getData(this.orderCid);
        this.isOrderDeadlineChange = false;
        this.toGoodsDeadline = "";
        this.requestReason = "";
      }
    },
    onClickPdfGenerator() {
      this.pdfGenerator();
    },
    async pdfGenerator() {
      const path = `${this.$apiPath.ORDER_PDF_GENERATOR}/${this.itemData.contractId}`;
      const result = await ApiService.shared.putData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
// @import '~@/assets/css/print.css';
.box_print {
  padding: 75px 0 72px;
  background-color: #f5f5f5;
  border-top: 2px solid #222;
}
.wrap_print {
  overflow: hidden;
  position: relative;
  width: 1090px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 1px 2px 12px rgba(0, 0, 0, 0.2);
}
.page_print + .page_print {
  border-top: 1px solid #e1e1e1;
}

.pdf-viewer {
  position: relative;
  border-top: 2px solid #222;
  & > span {
    max-width: 1280px;
    margin: 0 auto;
    border-top: 1px solid #e1e1e1;
    &:first-child {
      border-top: none;
    }
  }
  & .pdfviewer-error {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    &__btn {
      margin-top: 15px;
      font-size: 18px;
      & > .icon-realod {
        vertical-align: -4px;
      }
    }
  }
}
</style>
