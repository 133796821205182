<template>
  <Popup popupType="tableType" width="824px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">납기일 변경</h3>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 608px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>납기 변경일<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <DatePicker :dateString.sync="toGoodsDeadlineSync" />
              </td>
            </tr>
            <tr>
              <th>납기 변경사유<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <Textarea
                  :value.sync="requestReasonSync"
                  :maxLength="300"
                  :isCount="true"
                  placeholder="사유를 입력하세요."
                />
              </td>
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <div class="group_btn">
      <button class="btn_fourthly btn_medium" @click="onClickOrderDeadlineChangeClose">취소</button>
      <button class="btn_secondary btn_medium" @click="onClickOrderDeadlineChangeSubmit">
        확인
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";
import Textarea from "@/components/common/textarea/Textarea";
import DatePicker from "@/components/common/calendar/DatePicker";

import PageMixin from "@/mixins/PageMixin";

export default {
  name: "OrderDeadlineChange",
  components: {
    Popup,
    TableView,
    Textarea,
    DatePicker,
  },
  mixins: [PageMixin],
  props: {
    toGoodsDeadline: String,
    requestReason: String,
  },
  computed: {
    toGoodsDeadlineSync: {
      get() {
        return this.toGoodsDeadline;
      },
      set(value) {
        this.$emit("update:toGoodsDeadline", value);
      },
    },
    requestReasonSync: {
      get() {
        return this.requestReason;
      },
      set(value) {
        this.$emit("update:requestReason", value);
      },
    },
  },
  methods: {
    // 취소 버튼
    onClickOrderDeadlineChangeClose() {
      this.$emit("onClickOrderDeadlineChangeClose");
    },
    // 확인 버튼
    onClickOrderDeadlineChangeSubmit() {
      if (this.getAlertMessage()) {
        return;
      }

      this.$emit("onClickOrderDeadlineChangeSubmit");
    },
    getAlertMessage() {
      if (!this.toGoodsDeadline) {
        this.alert("납기 변경일을 입력하세요.");
        return true;
      }
      if (!this.requestReason) {
        this.alert("납기 변경사유를 입력하세요.");
        return true;
      }
      return false;
    },
  },
};
</script>
