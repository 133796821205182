<template>
  <tr>
    <!-- 변경요청일 -->
    <td class="align_center">
      {{ item.fromGoodsDeadline }}
      <span class="material-icons icons-deadline"> south </span>
      {{ item.toGoodsDeadline }}
    </td>
    <!-- 변경사유 -->
    <td class="td_preline align_top">{{ item.requestReason }}</td>
    <!-- 승인여부 -->
    <td class="align_center">
      {{ computedAgreeText }}
    </td>
    <!-- 승인일시 -->
    <td class="align_center">{{ item.agreeDate | dateTimeStringFormat }}</td>
    <!-- 승인거절 사유 -->
    <td class="td_preline align_top">{{ item.comment | nullToDash }}</td>
  </tr>
</template>

<script>
import Textarea from "@/components/common/textarea/Textarea";
import DatePicker from "@/components/common/calendar/DatePicker";
import { escapeRegExp } from "@/utils/spUtils";

import LocalStorageManager from "@/LocalStorageManager";
import ApiService from "@/services/ApiService";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "GoodsDeadlineHistoryLine",
  components: {
    Textarea,
    DatePicker,
  },

  props: {
    item: Object,
    orderCid: String,
  },
  data() {
    return {
      reqId: this.item.reqId,
      agreeYn: false,
      comment: "",
      agreeDate: this.moment(new Date()).format("YYYY-MM-DD"),
      manager: "",
    };
  },
  computed: {
    isAgreeYn() {
      return this.item.agreeYn === "";
    },
    computedAgreeText() {
      let ret = "대기";
      if (this.item.agreeYn === "") {
        ret = "대기";
      } else if (this.item.agreeYn) {
        ret = "승인";
      } else {
        ret = "거절";
      }
      return ret;
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.icons-deadline {
  display: block;
  font-size: 18px;
}
.btn_small {
  display: block;
  width: 80px;
  margin: 10px auto 0;
  &:first-child {
    margin-top: 0;
  }
}
</style>
